/* You can add global styles to this file, and also import other style files */

body,
html {
  margin: 0 auto;
  padding: 0;
  min-height: 130%;
}

body {
  touch-action: pan-x pan-y;
}

label {
  margin-bottom: 0;
}

.ngb-dp-header {
  direction: ltr;
}

.icofont-check-alt {
  font-weight: 500;
  font-size: 1.4rem;
}

.icofont-close-line {
  font-weight: 500;
  font-size: 1.35rem;
}

input[currencyMask] {
  direction: ltr;
}

input[type=tel] {
  font-size: 0.8rem;
}

.mirror-rotate {
  animation: mirror-rotate 2s infinite;
  animation-direction: alternate;
}

.date-stamp-wrapper {
  padding-top: 3vw;
  background-color: #F7F7FA;
}

.date-stamp {
  color: #6c757d;
  font-style: italic;
  text-align: center;
  font-size: 0.8rem;
}

@keyframes mirror-rotate {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.sort-by-button {
  text-align: center;
  color: #ffffff;
  font-weight: normal;
  font-size: 0.85rem;
  font-family: Arial;
  background-color: #C4C4C4;
  padding: 2.5vw 3vw;
  margin-top: 4vw;
  border-radius: 4px;
  border: none;
  width: 100%;
}

.sort-by-button:active {
  box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5), inset 8px 8px 16px rgba(0, 0, 0, 0.1);
}

.top-buttons {
  display: grid;
  justify-items: center;
  grid-template: auto / auto auto;
}

.empty-row,
.datatable-header-cell-template-wrap,
.datatable-body-cell-label,
.datatable-body {
  text-align: right !important;
}

i {
  font-size: 19px;
}

html,
body {
  margin: 0 0;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    max-width: 210mm;
  }
}